<template>
  <div id="app">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
    <PageHeader />
    <HomePage />
    <PageFooter />
    <!-- Transition for the loading screen -->
    <transition name="fade" appear @after-leave="afterLoadingScreenLeave">
      <div v-if="isInitialLoading" class="loading-screen">
        <LoadingScreen />
      </div>
    </transition>
  </div>
</template>

<script>
import PageHeader from "./components/PageHeader.vue";
import PageFooter from "./components/PageFooter.vue";
import HomePage from "./components/HomePage.vue";
import LoadingScreen from "./components/LoadingScreen.vue";

export default {
  name: "App",
  components: {
    HomePage,
    PageHeader,
    PageFooter,
    LoadingScreen,
  },
  data() {
    return {
      isInitialLoading: true, // Tracks the initial loading screen
      isLoading: true, // Tracks the content loading state
    };
  },
  mounted() {
    // Simulate initial loading delay (e.g., fetching data, assets, etc.)
    setTimeout(() => {
      console.log(this.isInitialLoading);
      this.isInitialLoading = false; // Start fading out the loading screen
      console.log(this.isInitialLoading);
    }, 4000); // Adjust timing for how long the loading screen is shown
  },
  methods: {
    afterLoadingScreenLeave() {
      // Additional logic after the loading screen has fully faded out (optional)
    },
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* Fade transition for both loading screen and content */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
  /* General fade transition duration */
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active for older versions */
  {
  opacity: 0;
  /* Start with opacity 0 for fade effect */
}

/* Additional styles for loading screen and content */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  /* Adjust as needed */
  z-index: 9999;
  /* Ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  opacity: 0;
  /* Initially hide content */
}
</style>
