<template>
     <div class="header-background py-8 px-8 xl:py-12 xl:px-16 2xl:p-16 text-white md:flex">
          <div
               class="md:w-3/4 lg:w-4/5 3xl:w-3/4 border-b border-b-1  md:border-b-0 md:border-r border-r-1 border-r-black justify-center space-y-4 pb-8 md:pb-0 md:pr-8">
               <h2 class="text-2xl">Find me on Instagram!</h2>
               <InstagramFeed />
          </div>
          <div class="md:w-1/4 flex items-center justify-center pt-8 md:pt-0">
               <div class="space-y-4 items-center justify-center w-full">
                    <h2 class="text-2xl">Follow my socials</h2>
                    <a href="https://www.instagram.com/author.n.training/" target="_blank" class="fa fa-instagram"></a>
               </div>
          </div>
     </div>
</template>

<script>
import InstagramFeed from '../components/InstagramFeed.vue';

export default {
     name: 'App',
     components: {
          InstagramFeed
     }
};
</script>