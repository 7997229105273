<template>

    <div class="justify-center">
        <div v-if="loading">Loading...</div>
        <div v-if="error">{{ error }}</div>
        <div v-if="media.length" class="flex justify-center">
            <button @click="rotateSelection('left')" class="feedController grayedOut" id="moveLeft">
                <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 572 572" width="20">
                    <title>left-arrowhead-variant-svgrepo-com-svg</title>
                    <g id="Layer">
                        <g id="Layer">
                            <path id="Layer" class="s0"
                                d="m76.2 296l415.8 272.2c8.3 6.6 12 3.9 8.4-6.1l-171-260c-3.7-10-3.6-26.2 0-36.1l170.9-256.3c3.7-10-0.1-12.7-8.4-6.1l-415.6 268.5c-8.3 6.6-8.4 17.3-0.1 23.9z" />
                        </g>
                    </g>
                </svg>
            </button>
            <div v-for="item in selectedMedia" :key="item.id" @click="openPost(item.permalink)"
                class="media-item mx-1 md:mx-3 rounded">
                <img class="reel-thumbnail" v-if="item.thumbnail_url" :src="item.thumbnail_url"
                    :alt="item.caption || 'Instagram reel'" />
                <img class="reel-image" v-else :src="item.media_url"
                    :alt="item.caption || 'Instagram instagram picture'" />
            </div>
            <button @click="rotateSelection('right')" class="feedController" id="moveRight">
                <svg class="" version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 572 572" width="20"
                    height="20">
                    <g id="Layer">
                        <g id="Layer">
                            <path id="Layer" class="s0"
                                d="m495.8 276l-415.8-272.2c-8.3-6.6-12.1-3.9-8.4 6.1l171 260c3.7 10 3.6 26.2 0 36.1l-170.9 256.3c-3.7 10 0.1 12.7 8.4 6.1l415.6-268.5c8.3-6.6 8.4-17.3 0.1-23.9z" />
                        </g>
                    </g>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            media: [],
            selectedMedia: [],
            selector: 0,
            loading: true,
            error: null,
            screenWidth: window.innerWidth,
        };
    },
    computed: {
        itemsPerPage() {
            if (this.screenWidth > 1480) {
                return 7; // Desktop
            } else if (this.screenWidth > 1024) {
                return 5; // Laptop
            } else if (this.screenWidth >= 768) {
                return 3; // Tablet
            } else {
                return 2; // Mobile
            }
        },
    },
    watch: {
        itemsPerPage() {
            this.updateSelectedMedia();
        },
        screenWidth() {
            this.updateSelectedMedia();
        }
    },
    mounted() {
        this.fetchInstagramMedia();
        this.updateSelectedMedia();
    },
    methods: {
        async fetchInstagramMedia() {
            try {
                const token = process.env.VUE_APP_INSTAGRAM_USER_TOKEN;
                const response = await axios.get(`https://graph.instagram.com/me/media`, {
                    params: {
                        fields: 'media_url,thumbnail_url,permalink',
                        access_token: token
                    }
                });
                this.media = response.data.data;
                this.updateSelectedMedia();
            } catch (err) {
                console.log(err);
                this.error = 'Failed to fetch Instagram media.';
            } finally {
                this.loading = false;
            }
        },
        rotateSelection(direction) {
            const left = document.getElementById("moveLeft");
            const right = document.getElementById("moveRight");
            switch (direction) {
                case "left":
                    this.selector = Math.max(this.selector - this.itemsPerPage, 0);
                    right.classList.remove("grayedOut");
                    break;
                case "right":
                    this.selector = Math.min(this.selector + this.itemsPerPage, this.media.length - this.itemsPerPage);
                    left.classList.remove("grayedOut");
                    break;
            }
            if (this.selector <= 0) {
                this.selector = 0;
                left.classList.add("grayedOut");
            }
            else if (this.selector + this.itemsPerPage >= this.media.length) {
                this.selector = this.media.length - this.itemsPerPage;
                right.classList.add("grayedOut");
            }
            this.updateSelectedMedia();
        },
        updateSelectedMedia() {
            this.selectedMedia = this.media.slice(this.selector, this.selector + this.itemsPerPage);
        },
        openPost(openedLink) {
            window.open(openedLink, '_blank');
            console.log(openedLink);
        },
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        },
    },
    created() {
        window.addEventListener('resize', this.updateScreenWidth);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateScreenWidth);
    },
};
</script>

<style>
.media-item {
    width: 150px !important;
    height: 150px !important;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    border: solid 1px;
    border-color: black;
    transition: all ease-in-out 0.25s;
}

.media-item:hover {
    border-color: goldenrod;
}

.reel-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the image covers the container */
    object-position: center;
    /* Centers the image */
}

.reel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the image covers the container */
    object-position: center;
    /* Centers the image */
}

.flipped {
    transform: "scale(2,2) scale(-1,1)"
}

.feedController {
    transition: all ease-in-out 0.25s;
}

.feedController:hover {
    fill: goldenrod;
}

.grayedOut {
    fill: gray;
    pointer-events: none;
}
</style>