<template>
    <div class="pb-16 md:pb-32 lg:pb-8 2xl:pb-24 px-4 sm:px-24 lg:px-16 3xl:px-44 flex-col flex justify-center items-center"
        loading="lazy">
        <div class="opaqueBackground rounded w-full justify-center items-center flex flex-col">
            <div class="container mx-auto my-10 lg:my-5 flex items-center ">
                <div class="border-b-2 border-gray-400 flex-grow"></div>
                <div class="mx-5">
                    <h1 class="text-4xl">The Runaway Trilogy</h1>
                </div>
                <div class="border-b-2 border-gray-400 flex-grow"></div>
            </div>

            <div
                class="w-full xl:w-4/5 2xl-3/5 lg:px-8 xl:px-24 3xl:px-56 lg:py-12 flex flex-col lg:flex-row items-center justify-center">
                <div class="book-wrapper justify-center flex flex-col lg:flex-row">
                    <div v-show="!selectedBook || selectedBook === 1" @click="selectBook(1)"
                        class="book-items lg:max-w-sm">
                        <div class="main-book-wrap">
                            <div class="book-cover">
                                <div class="book-inside"></div>
                                <div class="book-image" id="book1">
                                    <img src="../public/fight-min.png" alt="Cover of The Things We Fight For" />
                                    <div class="effect"></div>
                                    <div class="light"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="!selectedBook || selectedBook === 2" @click="selectBook(2)"
                        class="book-items lg:max-w-sm">
                        <div class="main-book-wrap">
                            <div class="book-cover">
                                <div class="book-inside"></div>
                                <div class="book-image" id="book2">
                                    <img src="../public/run-min.png" alt="Cover of The Things We Run For" />
                                    <div class="effect"></div>
                                    <div class="light"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="!selectedBook || selectedBook === 3" @click="selectBook(3)"
                        class="book-items lg:max-w-sm">
                        <div class="main-book-wrap">
                            <div class="book-cover">
                                <div class="book-inside"></div>
                                <div class="book-image" id="book3">
                                    <img src="../public/stay-min.png" alt="Cover of The Things We Stay For" />
                                    <div class="effect"></div>
                                    <div class="light"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-2/3 2xl:w-1/2 lg:border p-8 space-y-8 flex flex-col justify-center items-center opaqueBackground rounded"
                    v-show="selectedBook === 1">
                    <div class="infoText">
                        <p>
                            <i>Fight.</i> Protect yourself and those you love. <br /><br />
                            The prequel to “The Things We RUN For” <br /><br />
                            Blurb coming soon!
                        </p>
                    </div>
                    <button @click="resetSelection(1)" class="aboutText aboutButton py-1.5 px-3 rounded">
                        Explore other Entries
                    </button>
                </div>
                <div class="w-full lg:w-2/3 2xl:w-1/2 lg:border p-8 space-y-8 flex flex-col justify-center items-center opaqueBackground rounded"
                    v-show="selectedBook === 2">
                    <div class="infoText">
                        <p>
                            <i>Run.</i> What a powerful instinct. <br /><br />
                            Emma Bridge had wanted nothing more than to be with the people she loved in a place that
                            felt safe, but safety isn’t handed out to those who simply ask for it. One last blow to her
                            will was all it took for her to set her fantasy of a plan into motion. Leaving the spotlight
                            of the Hills, Emma runs away to a town so small the only air it had ever gotten was a
                            suicide that briefly made the news.
                            <br /><br />
                            Now separated from her best friend and younger brother, Emma scouts out the town with her
                            new clothes and an even newer name, but her new home is anything but safe. <br /><br />
                            The weight of the world has fallen on her shoulders, and she can’t carry it alone. But with
                            her old name in every police station and her new name on every tongue in town, she’s not
                            sure who she can trust to help carry the burden.
                        </p>
                    </div>
                    <button @click="resetSelection(2)" class="aboutText aboutButton py-1.5 px-3 rounded">
                        Explore other Entries
                    </button>
                </div>
                <div class="w-full lg:w-2/3 2xl:w-1/2 lg:border p-8 space-y-8 flex flex-col justify-center items-center opaqueBackground rounded"
                    v-show="selectedBook === 3">
                    <div class="infoText w-full">
                        <p>
                            <i>Stay.</i> This is our home. <br />
                            <br />The sequel to “The Things We RUN For” <br /><br />Blurb coming soon!
                        </p>
                    </div>
                    <button @click="resetSelection(3)" class="aboutText aboutButton py-1.5 px-3 rounded">
                        Explore other Entries
                    </button>
                </div>
            </div>
        </div>
        <!-- <div class="container mx-auto mt-5 flex items-center pb-4">
            <div class="border-b-2 border-gray-400 flex-grow"></div>
            <div class="mx-5">
                <h1>The Runaway Trilogy</h1>
            </div>
            <div class="border-b-2 border-gray-400 flex-grow"></div>
        </div>

        <div class="lg:w-4/5 2xl-3/5 lg:px-24 2xl:px-56 lg:py-12 flex flex-col md:flex-row items-center justify-center">
            <div class="book-wrapper justify-center flex flex-col md:flex-row">
                <div v-show="!selectedBook || selectedBook === 1" @click="selectBook(1)" class="book-items lg:max-w-sm">
                    <div class="main-book-wrap">
                        <div class="book-cover">
                            <div class="book-inside"></div>
                            <div class="book-image" id="book1">
                                <img src="../public/fight-min.png" alt="Cover of The Things We Fight For" />
                                <div class="effect"></div>
                                <div class="light"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="!selectedBook || selectedBook === 2" @click="selectBook(2)" class="book-items lg:max-w-sm">
                    <div class="main-book-wrap">
                        <div class="book-cover">
                            <div class="book-inside"></div>
                            <div class="book-image" id="book2">
                                <img src="../public/run-min.png" alt="Cover of The Things We Run For" />
                                <div class="effect"></div>
                                <div class="light"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="!selectedBook || selectedBook === 3" @click="selectBook(3)" class="book-items lg:max-w-sm">
                    <div class="main-book-wrap">
                        <div class="book-cover">
                            <div class="book-inside"></div>
                            <div class="book-image" id="book3">
                                <img src="../public/stay-min.png" alt="Cover of The Things We Stay For" />
                                <div class="effect"></div>
                                <div class="light"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="w-full lg:w-2/3 2xl:w-1/2 lg:border p-8 space-y-8 flex flex-col justify-center items-center opaqueBackground rounded"
                v-show="selectedBook === 1">
                <div class="infoText">
                    <p>
                        <i>Fight.</i> Protect yourself and those you love. <br /><br />
                        The prequel to “The Things We RUN For” <br /><br />
                        Blurb coming soon!
                    </p>
                </div>
                <button @click="resetSelection(1)" class="aboutText aboutButton py-1.5 px-3 rounded">
                    Explore other Entries
                </button>
            </div>
            <div
                class="w-full lg:w-2/3 2xl:w-1/2 lg:border p-8 space-y-8 flex flex-col justify-center items-center opaqueBackground rounded"
                v-show="selectedBook === 2">
                <div class="infoText">
                    <p>
                        <i>Run.</i> What a powerful instinct. <br /><br />
                        Emma Bridge had wanted nothing more than to be with the people she loved in a place that felt
                        safe, but safety isn’t handed out to those who simply ask for it. One last blow to her will was
                        all it took for her to set her fantasy of a plan into motion. Leaving the spotlight of the
                        Hills, Emma runs away to a town so small the only air it had ever gotten was a suicide that
                        briefly made the news.
                        <br /><br />
                        Now separated from her best friend and younger brother, Emma scouts out the town with her new
                        clothes and an even newer name, but her new home is anything but safe. <br /><br />
                        The weight of the world has fallen on her shoulders, and she can’t carry it alone. But with her
                        old name in every police station and her new name on every tongue in town, she’s not sure who
                        she can trust to help carry the burden.
                    </p>
                </div>
                <button @click="resetSelection(2)" class="aboutText aboutButton py-1.5 px-3 rounded">
                    Explore other Entries
                </button>
            </div>
            <div
                class="w-full lg:w-2/3 2xl:w-1/2 lg:border p-8 space-y-8 flex flex-col justify-center items-center opaqueBackground rounded"
                v-show="selectedBook === 3">
                <div class="infoText w-full">
                    <p>
                        <i>Stay.</i> This is our home. <br />
                        <br />The sequel to “The Things We RUN For” <br /><br />Blurb coming soon!
                    </p>
                </div>
                <button @click="resetSelection(3)" class="aboutText aboutButton py-1.5 px-3 rounded">
                    Explore other Entries
                </button>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedBook: null
        };
    },
    methods: {
        selectBook(bookNumber) {
            this.selectedBook = bookNumber;
            document.getElementById('book' + bookNumber).classList.add('clicked');
        },
        resetSelection(bookNumber) {
            this.selectedBook = null;
            document.getElementById('book' + bookNumber).classList.remove('clicked');
        }
    }
};
</script>

<style>
@media (max-width: 1919px) and (min-width: 1024px) {
    .book-items {
        max-width: 280px;
    }
}

@media (max-width: 1023px) {
    .flower-background {
        background-image: url('../public/Flower_1.png'), url('../public/Flower_2.png');
        /* Adjust paths as needed */
        background-position: -200px -300px, calc(100% + 150px) calc(100%);
        /* Positions the images */
        background-size: 600px 600px, 600px 600px;
        /* Adjust size as needed */
        background-repeat: no-repeat, no-repeat;
        /* Prevents repeating */
        position: relative;
        /* Keeps the div's positioning context */
    }
}
</style>
